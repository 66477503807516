<template>
    <v-container>
        <v-row class="pa-0" justify="center">
            <v-col cols="12" lg="9" xl="7" class="pa-0">
                <v-container class="clientcabin clientcabin-footer">
                    <v-row>
                        <v-col cols="12" sm="4" class="footer-group">
                            <span class="text-h6">Also See</span>
                            <ul class="mt-3 text-body-1">
                                <li>
                                    <a href="/app/reasons" target="_blank">
                                        Results & Reasons
                                    </a>
                                </li>
                                <li>
                                    <a href="/app/about" target="_blank">
                                        About Us
                                    </a>
                                </li>
                                <li v-if="options?.booking_url">
                                    <a
                                        :href="options?.booking_url"
                                        target="_blank"
                                    >
                                        Book a Call
                                    </a>
                                </li>
                                <li v-if="hasCustomFooter">
                                    <a :href="customFooterLink" target="_blank">
                                        {{ customFooterLabel }}
                                    </a>
                                </li>
                            </ul>
                        </v-col>
                        <v-col
                            v-if="hasSocialLinks"
                            cols="12"
                            sm="4"
                            class="footer-group"
                        >
                            <span class="text-h6">Stay Connected</span>
                            <ul class="mt-3 text-body-1">
                                <li v-if="facebookLink">
                                    <a :href="facebookLink" target="_blank">
                                        <v-icon left>
                                            fab fa-facebook-square
                                        </v-icon>
                                        <span>Facebook</span>
                                    </a>
                                </li>
                                <li v-if="linkedinLink">
                                    <a :href="linkedinLink" target="_blank">
                                        <v-icon left>fab fa-linkedin</v-icon>
                                        <span>LinkedIn</span>
                                    </a>
                                </li>
                                <li v-if="twitterLink">
                                    <a :href="twitterLink" target="_blank">
                                        <v-icon left>
                                            fab fa-square-x-twitter
                                        </v-icon>
                                        <span>Twitter</span>
                                    </a>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            align-self="center"
                            class="footer-group pt-lg-8 mb-n5 text-center boulder--text text-body-1"
                        >
                            <a class="mr-2" href="/users/terms" target="_blank">
                                Terms of Service
                            </a>
                            |
                            <a
                                class="ml-2"
                                href="/users/terms#privacy"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            align-self="center"
                            class="text-center boulder--text text-body-1"
                        >
                            © {{ currentYear }} {{ options?.business_name }}.
                            All rights reserved.
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class ClientCabinFooter extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get currentYear() {
        return new Date().getFullYear();
    }

    get hasCustomFooter() {
        return Boolean(
            this.options?.options?.footer?.link_text &&
                this.options?.options?.footer?.link_url
        );
    }

    get customFooterLink() {
        return this.options?.options?.footer?.link_url || '';
    }

    get customFooterLabel() {
        return this.options?.options?.footer?.link_text || '';
    }

    get facebookLink() {
        return this.options?.social_media?.facebook;
    }

    get twitterLink() {
        return this.options?.social_media?.twitter;
    }

    get linkedinLink() {
        return this.options?.social_media?.linkedin;
    }

    get hasSocialLinks() {
        return !![
            this.facebookLink,
            this.twitterLink,
            this.linkedinLink
        ].filter(Boolean).length;
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-footer::v-deep {
    * {
        font-family: 'Segoe UI', '-apple-system', BlinkMacSystemFont, Roboto,
            'Helvetica Neue', Helvetica, Ubuntu, Arial, sans-serif !important;
    }
    .footer-group {
        color: #282b30;

        ul {
            list-style: none;
            padding-left: 0;

            li {
                padding-top: 0.7em;
            }
        }

        a {
            color: #282b30;
            transition: all 0.15s ease-out;
            text-decoration: none;

            &:hover {
                color: $primary-color;
            }
        }
    }
}
</style>
