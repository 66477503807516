import { isClientCabin } from '@/utils/helpers';

import type { RouteConfig, NavigationGuardNext, Route } from 'vue-router';

function beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext) {
    if (isClientCabin()) {
        next();
    } else {
        next('/');
    }
}

const routes: RouteConfig[] = [
    {
        path: '/info',
        name: 'ClientCabinInfo',
        component: () => import('@/pages/ClientCabin/Info'),
        meta: { title: 'Grow Your Business', configurable: true },
        beforeEnter
    },
    {
        path: '/about',
        name: 'ClientCabinAbout',
        component: () => import('@/pages/ClientCabin/About'),
        meta: { title: 'About Us', configurable: true },
        beforeEnter
    },
    {
        path: '/book',
        name: 'ClientCabinBook',
        component: () => import('@/pages/ClientCabin/Book'),
        meta: { title: 'Book Appointment', configurable: true },
        beforeEnter
    },
    {
        path: '/proposal',
        name: 'ClientCabinProposal',
        component: () => import('@/pages/ClientCabin/Proposal'),
        meta: { title: 'Grow Your Business', configurable: true },
        beforeEnter
    },
    {
        path: '/examples',
        name: 'ClientCabinExamples',
        component: () => import('@/pages/ClientCabin/Examples'),
        meta: { title: 'Examples' },
        beforeEnter
    },
    {
        path: '/reasons',
        name: 'ClientCabinReasons',
        component: () => import('@/pages/ClientCabin/Reasons'),
        meta: { title: 'Results & Reasons' },
        beforeEnter
    },
    {
        path: '/reasons/megaphone-effect',
        name: 'ClientCabinReasonsR1',
        component: () => import('@/pages/ClientCabin/Reasons/BoostYourBrand'),
        meta: { title: 'Megaphone Effect' },
        beforeEnter
    },
    {
        path: '/reasons/successful-wine-marketing-example',
        name: 'ClientCabinStoryS1',
        component: () => import('@/pages/ClientCabin/Reasons/WineMarketing'),
        meta: { title: 'Successful Wine Marketing Example' },
        beforeEnter
    },
    {
        path: '/thanks/:product?',
        name: 'ClientCabinThanks',
        component: () => import('@/pages/ClientCabin/Thanks'),
        props: true,
        meta: { title: 'Thanks!' },
        beforeEnter
    },
    {
        path: '/premium',
        name: 'ClientCabinPremium',
        component: () => import('@/pages/ClientCabin/Premium'),
        meta: { title: 'The Big 6' },
        beforeEnter
    },
    {
        path: '/defi-distribution',
        name: 'ClientCabinDeFi',
        component: () => import('@/pages/ClientCabin/DeFi'),
        meta: { title: 'DeFi Distribution' },
        beforeEnter
    },
    {
        path: '/blow',
        name: 'ClientCabinBLOW',
        component: () => import('@/pages/ClientCabin/BLOW'),
        meta: { title: 'Blog Link Outreach Wire (B.L.O.W.)' },
        beforeEnter
    },
    {
        path: '/msn',
        name: 'ClientCabinMsn',
        component: () => import('@/pages/ClientCabin/MSN'),
        meta: { title: 'MSN Distribution' },
        beforeEnter
    }
];

export default {
    routes,
    index: routes[0].path
};
