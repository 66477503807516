<template>
    <v-app-bar app fixed :color="barBackgroundColor" class="header">
        <loading />
        <v-container class="inner-container fill pa-0">
            <v-row class="pa-0 fill" justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-0 px-md-2">
                    <v-container
                        class="header-container fill"
                        :fluid="$vuetify.breakpoint.mdAndDown"
                    >
                        <v-row
                            class="header-wrapper fill"
                            align="center"
                            justify="space-between"
                        >
                            <v-col
                                cols="auto"
                                class="d-flex align-center logo-container py-0 justify-center"
                            >
                                <router-link
                                    v-if="isLogoLoaded"
                                    to="/"
                                    :class="{
                                        'text-h6 text-decoration-none': !hasLogo
                                    }"
                                >
                                    <v-img
                                        v-if="hasLogo"
                                        :src="logo.src"
                                        :alt="logo.alt"
                                        position="top left"
                                        max-height="64"
                                        max-width="180"
                                        contain
                                    />
                                    <div v-else>
                                        {{ businessName }}
                                    </div>
                                </router-link>
                            </v-col>

                            <v-col
                                v-if="$vuetify.breakpoint.mdAndUp"
                                cols="auto"
                                class="navbar-container fill"
                            >
                                <v-list
                                    class="d-inline-flex navbar-wrapper fill"
                                    :color="barBackgroundColor"
                                >
                                    <v-list-item
                                        v-for="(tab, i) in availableTabs"
                                        :key="`tab-${i}`"
                                        :to="tab.to"
                                        class="navbar-item"
                                        color="white"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ tab.title }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                                <v-btn
                                    v-if="shouldShowBooking"
                                    :small="$vuetify.breakpoint.smAndDown"
                                    class="primary ml-md-4"
                                    :to="bookingUrl"
                                >
                                    Book Appointment
                                </v-btn>
                            </v-col>

                            <v-col v-else class="pa-0 d-flex justify-end">
                                <v-btn
                                    v-if="shouldShowBooking"
                                    :small="$vuetify.breakpoint.smAndDown"
                                    class="primary mr-md-4 mt-3"
                                    :to="bookingUrl"
                                >
                                    Book Appointment
                                </v-btn>
                                <v-menu offset-y bottom left>
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            v-if="hasMenuToRender"
                                            v-bind="attrs"
                                            fab
                                            elevation="0"
                                            color="white"
                                            v-on="on"
                                        >
                                            <v-icon>bars</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item-group
                                            v-if="$vuetify.breakpoint.smAndDown"
                                            class="navbar-menu-container"
                                        >
                                            <v-list-item
                                                v-for="(
                                                    tab, i
                                                ) in availableTabs"
                                                :key="`tab-${i}`"
                                                :to="tab.to"
                                                class="navbar-item"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ tab.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { Loading } from '@/components/Loading';
import { InjectReactive } from '@/utils/decorators';
import { ClientCabin } from '@/types/ClientCabin';

@Component({
    computed: {
        ...mapGetters('logo', ['logo']),
        ...mapState('user', ['businessName'])
    },
    components: {
        Loading
    }
})
export default class ClientCabinHeader extends Vue {
    logo!: { src: string; alt: string };
    businessName!: string;

    hasLogo = false;

    isLogoLoaded = false;

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get tabs() {
        return [
            {
                title: 'Learn More',
                to: '/proposal',
                visible: this.shouldShowLearnMoreLink
            },
            {
                title: 'Results & Reasons',
                to: '/reasons',
                visible: this.shouldShowHeaderLink
            },
            {
                title: 'About Us',
                to: '/about',
                visible: this.shouldShowHeaderLink
            }
        ];
    }

    get hasMenuToRender() {
        return Boolean(this.availableTabs.length);
    }

    get availableTabs() {
        return this.tabs.filter(tab => tab.visible);
    }

    get barBackgroundColor(): string {
        if (this.$route.path === '/welcome') {
            return '#F4FAFF';
        }

        return 'white';
    }

    get authenticated() {
        return this.$store.getters['user/hasActiveSession'];
    }

    get bookingUrl() {
        return '/book';
    }

    get shouldShowBooking() {
        return (
            ![
                '/book',
                '/info',
                '/premium',
                '/defi-distribution',
                '/blow'
            ].includes(this.$route.path) && this.options?.booking_url
        );
    }

    get shouldShowHeaderLink() {
        return !['/info', '/premium', '/defi-distribution', '/blow'].includes(
            this.$route.path
        );
    }

    get shouldShowLearnMoreLink() {
        return ![
            '/info',
            '/premium',
            '/defi-distribution',
            '/blow',
            '/proposal'
        ].includes(this.$route.path);
    }

    created() {
        if (this.authenticated) {
            this.$store.dispatch('analytics/identifyUser');
        }

        this.getLogo();
    }

    async getLogo() {
        this.$http.get('/users/get_logo').then(({ data }) => {
            this.hasLogo = !`${data.data?.logo?.file}`.includes(
                'logo.ampifire.com.svg'
            );

            this.$store.commit('logo/update', {
                src: data.data?.logo?.file,
                alt: data.data?.site
            });

            this.setFavicon(data.data?.logo?.file);

            this.isLogoLoaded = true;
        });
    }

    setFavicon(src: string) {
        const link: HTMLLinkElement | null =
            document.querySelector("link[rel~='icon']");

        if (link) {
            link.href = src;
        }
    }
}
</script>

<style lang="scss" scoped>
.header::v-deep {
    height: 64px !important;
    box-shadow: 0px 2px 4px 0px #0000000d !important;

    .fill {
        height: 100%;
    }

    .inner-container {
        display: flex;
        align-items: center;
    }

    .v-toolbar__content {
        align-items: start;
        padding: 0 !important;

        .header-container {
            padding: 0 !important;

            .header-wrapper {
                margin: 0;
                flex-wrap: nowrap;

                .logo-container {
                    width: 180px;
                }

                .navbar-container {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;

                    .navbar-wrapper {
                        padding: 0;

                        .navbar-item {
                            border-bottom: 4px solid transparent;
                            transition: 0.1s ease-in;
                            color: black;

                            &:hover {
                                border-bottom: 4px solid $primary-color !important;
                            }

                            .v-list-item__content {
                                margin-top: -4px;
                            }

                            &.v-list-item--active {
                                border-bottom: 4px solid $primary-color;

                                .v-list-item__content {
                                    color: $primary-color !important;
                                }
                            }
                        }
                    }
                }

                .v-btn__content {
                    text-transform: none;
                }
            }
        }
    }
}
</style>
